<template>
  <div id="administration-payout-methods">
    <!-- list filters -->
    <v-card>
      <v-card-title>Payout Methods</v-card-title>

      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="12"
          md="6"
        >
          <v-select
            v-model="shopFilterModel"
            placeholder="Shop"
            :items="shopFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="6"
        >
          <v-select
            v-model="countryFilterModel"
            placeholder="Country"
            :items="countryFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-btn
          color="primary"
          class="mb-4 me-3"
          :loading="loading"
          @click.prevent="applyFilters"
        >
          Apply Filters
        </v-btn>

        <v-btn
          color="secondary"
          outlined
          class="mb-4"
          type="reset"
          @click.prevent="resetFilters"
        >
          Reset
        </v-btn>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="searchFilterModel"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
          dense
          outlined
          class="mb-4 me-0"
          @keyup.enter="searchByKeyword"
        ></v-text-field>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="payoutMethodItems"
        :options.sync="options"
        :server-items-length="payoutMethodItemsLength"
        :items-per-page="itemsPerPage"
        :loading="loading"
        show-current-page
        loading-text="Loading... Please wait"
        :footer-props="footerTableProps"
      >
        <template #[`item.methodName`]="{ item }">
          <div class="d-flex align-center">
            <span
              class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.methodName }}</span>
          </div>
        </template>

        <template #[`item.currencies`]="{ item }">
          <div
            v-for="cd in item.currencies"
            :key="cd.code"
          >
            <div>
              <code>{{ cd.code }}</code>: <code>Max: {{ cd.max }}</code> <code>Min: {{ cd.min }}</code>
            </div>
          </div>
        </template>

        <template #[`item.merchantDiscountRateConfig`]="{ item }">
          <div>
            <div>
              {{ formatCurrency(item.merchantDiscountRateConfig.percentage) }}% + {{ formatCurrency(item.merchantDiscountRateConfig.fixed) }}
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// sidebar
import {
  mdiMagnify,
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
} from '@mdi/js'
import { onMounted, onUnmounted } from '@vue/composition-api'
import { avatarText, formatDate, formatCurrency } from '@core/utils/filter'
import store from '@/store'
import storeModule from './storeModule'
import usePayoutMethods from './usePayoutMethods'

export default {
  setup() {
    const STORE_MODULE_NAME = 'processing-payout-methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const {
      tableColumns,
      payoutMethodItems,
      payoutMethodItemsLength,
      searchFilterModel,
      shopFilterModel,
      shopFilterItems,
      countryFilterModel,
      countryFilterItems,
      loading,
      loaders,
      options,
      footerTableProps,
      itemsPerPage,
      fetchList,
      searchByKeyword,
      applyFilters,
      resetFilters,
      fetchConfig,
    } = usePayoutMethods()

    onMounted(() => {
      fetchConfig()
    })

    return {
      tableColumns,
      payoutMethodItems,
      payoutMethodItemsLength,
      searchFilterModel,
      shopFilterModel,
      shopFilterItems,
      countryFilterModel,
      countryFilterItems,
      loading,
      loaders,
      options,
      footerTableProps,
      itemsPerPage,
      avatarText,
      fetchList,
      searchByKeyword,
      applyFilters,
      resetFilters,
      fetchConfig,
      formatDate,
      formatCurrency,

      // icons
      icons: {
        mdiMagnify,
        mdiAlertCircleOutline,
        mdiCheckCircleOutline,
      },
    }
  },
}
</script>

<style scoped>
</style>
