import { ref, watch } from '@vue/composition-api'
import {
  mdiPageFirst,
  mdiPageLast,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import store from '@/store'

export default function usePayoutMethods() {
  const payoutMethodItems = ref([])
  const payoutMethodItemsLength = ref(0)

  const tableColumns = [
    { text: 'UID', value: 'methodCode', sortable: false },
    { text: 'NAME', value: 'methodName' },
    { text: 'SHOP NAME', value: 'shopName' },
    {
      text: 'COUNTRY', value: 'countryName', sortable: false, align: 'center',
    },
    {
      text: 'MDR', value: 'merchantDiscountRateConfig', sortable: false, align: 'center',
    },
    {
      text: 'CURRENCIES', value: 'currencies', sortable: false, align: 'center',
    },
  ]

  const shopFilterModel = ref(null)
  const shopFilterItems = ref([])
  const countryFilterModel = ref(null)
  const countryFilterItems = ref([])
  const searchFilterModel = ref(null)

  const loading = ref(false)
  const loaders = ref({})

  const options = ref({
    sortBy: ['methodName'],
    sortDesc: [false],
  })

  const footerTableProps = {
    showFirstLastPage: true,
    itemsPerPageOptions: [10, 20, 50, 100],
    showCurrentPage: true,
    firstIcon: mdiPageFirst,
    lastIcon: mdiPageLast,
    prevIcon: mdiChevronLeft,
    nextIcon: mdiChevronRight,
  }

  const itemsPerPage = ref(footerTableProps[0])

  // fetch data
  const fetchList = () => {
    loading.value = true

    store
      .dispatch('processing-payout-methods/fetchList', {
        options: options.value,
        shopId: shopFilterModel.value,
        countryCode: countryFilterModel.value,
      })
      .then(response => {
        const { records, count } = response.data
        payoutMethodItems.value = records
        payoutMethodItemsLength.value = count
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  const searchByKeyword = () => {
    options.value.page = 1

    loading.value = true

    store
      .dispatch('processing-payout-methods/fetchList', {
        search: searchFilterModel.value,
        options: options.value,
      })
      .then(response => {
        const { records, count } = response.data
        payoutMethodItems.value = records
        payoutMethodItemsLength.value = count
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  watch([options], () => {
    fetchList()
  })

  const applyFilters = () => {
    options.value.page = 1
    fetchList()
  }

  const resetFilters = () => {
    searchFilterModel.value = null
    shopFilterModel.value = null
    countryFilterModel.value = null
  }

  const fetchConfig = () => {
    loading.value = true
    store
      .dispatch('processing-payout-methods/fetchConfig')
      .then(response => {
        const config = response.data
        shopFilterItems.value = config.shops
        countryFilterItems.value = config.countries
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  return {
    tableColumns,
    payoutMethodItems,
    payoutMethodItemsLength,
    searchFilterModel,
    shopFilterModel,
    shopFilterItems,
    countryFilterModel,
    countryFilterItems,
    loading,
    loaders,
    options,
    footerTableProps,
    itemsPerPage,
    applyFilters,
    resetFilters,
    fetchList,
    fetchConfig,
    searchByKeyword,
  }
}
